import { useCssVars as _useCssVars } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cd9e604"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

import { Plus } from '@element-plus/icons-vue'
import {ref,toRaw} from "vue"

export default {
  props: {fileList:Array},
  emits: ['upload_url'],
  setup(__props, { emit: emits }) {

const props = __props

_useCssVars(_ctx => ({
  "5c5c4994": (display.value)
}))



const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const display =ref("inline-block")
const handleRemove = (file, fileList) => {
  emits('upload_url',toRaw(fileList))
  display.value = toRaw(fileList).length<3?"inline-block":"none"
}
const handleAvatarChange = (res,file) => {
  display.value = toRaw(file).length<3?"inline-block":"none"
  emits('upload_url',toRaw(file))
}
const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url
  dialogVisible.value = true
}


return (_ctx, _cache) => {
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_upload = _resolveComponent("el-upload")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_upload, {
      action: "https://jsonplaceholder.typicode.com/posts/",
      "list-type": "picture-card",
      "on-change": handleAvatarChange,
      "on-remove": handleRemove,
      "file-list": __props.fileList,
      limit: 3,
      "on-preview": handlePictureCardPreview,
      "auto-upload": false,
      class: "uploadBox"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_icon, null, {
          default: _withCtx(() => [
            _createVNode(_unref(Plus))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["file-list"]),
    _createVNode(_component_el_dialog, {
      modelValue: dialogVisible.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((dialogVisible).value = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          style: {"width":"100%"},
          src: dialogImageUrl.value,
          alt: ""
        }, null, 8, _hoisted_1)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

}